<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
    page() {
        return {
            title: this.title,
            meta: [{ name: "description" }],
        };
    },
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Starter page",
            items: [
                {
                    text: "Utility",
                    href: "/",
                },
                {
                    text: "Starter page",
                    active: true,
                },
            ],

            ismobile: false,
            activeNames: [],
            activeapp: [],
            loading: true,
            hdinfo: [],
            subid: "",
            switcharray: [],
            reset: true,
            devicehdlist: [],
            cocktailsetting_device: [],
            cocktailsetting: [],
            cocktailsettingpage: "hdinfo",
            cocktailsetting_time: [],
            selectedGid: 0,
            goodslist: [],
        };
    },
    created() {
        var that = this;
        that.title = that.$t("device.detail.cocktail.setting");
        that.items = [
            {
                text: that.$t("menuitems.devices.text"),
                href: "/",
            },
            {
                text: that.$t("menuitems.devices.lists"),
                href: "/device/list",
            },
            {
                text: that.$t("menuitems.devices.devicedetail"),
                href: "/device/devicedetail?id=" + this.$route.query.id,
            },
            {
                text: that.$t("device.detail.cocktail.setting"),
                active: true,
            },
        ];

        that.userinfo = JSON.parse(localStorage.getItem("user"));
        let flag = navigator.userAgent.match(
            /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
        if (flag) {
            // that.ismobile = true;
        }
        that.gethdinfo();
    },
    methods: {
        gethdinfo() {
            var that = this;
            that.$axios
                .post(
                    that.apiuri,
                    {
                        action: "gethdinfo",
                        id: that.$route.query.id,
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    that.loading = false;
                    let data = response.data;
                    that.devicehdlist = data.data.hd;
                    that.cocktailsetting_device = that.$route.query.id
                    that.getgoodslist();
                    that.getcocktailsettingv2(that.$route.query.id);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getgoodslist() {
            var that = this;
            that.$axios
                .post(
                    that.apiuri,
                    {
                        action: "goodslist",
                        id: that.$route.query.id,
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    that.goodslist = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getcocktailsettingv2(deviceid) {
            var that = this;
            that.$axios
                .post(
                    that.apiuri,
                    {
                        action: "getcocktailsetting",
                        id: deviceid,
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    that.loading = false;
                    let data = response.data;
                    if (data.status == 404) {
                        that.cocktailsetting = {
                            "base": ["", "", "", "", "", "", "", "", ""],
                            "syrup": ["", "", "", "", "", ""],
                            "soda": "",
                            "moter": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                        }
                    } else {
                        that.cocktailsetting = data.setting
                        if (!that.cocktailsetting) {
                            that.cocktailsetting = {
                                "base": ["", "", "", "", "", "", "", "", ""],
                                "syrup": ["", "", "", "", "", ""],
                                "soda": "",
                                "moter": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                            }
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        addhd(id) {
            var that = this;
            that.$axios.post(that.apiuri, { action: "addhd", id: id })
                .then(function (response) {
                    if (response.status == 200) {
                        that.getcocktailsettingv2(id);

                    } else if (response.status == 500) {

                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        save_cocktail_base() {
            var that = this;
            this.$axios
                .post(that.apiuri, { action: "savecocktailsetting", id: that.cocktailsetting_device, setting: that.cocktailsetting })
                .then(function (response) {
                    if (response.data.status == 200) {
                        this.$confirm('保存成功')
                            .then(_ => {
                                that.getcocktailsettingv2(that.cocktailsetting_device);
                            })
                            .catch(_ => { });


                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getcocktailsettingtime() {
            var that = this;
            this.$axios
                .post(that.apiuri, { action: "getcocktailsettingtime", hid: that.selectedGid })
                .then(function (response) {
                    if (response.data.status == 200) {
                        that.cocktailsetting_time = response.data.setting;
                    } else {
                        that.cocktailsetting_time = {
                            "base": [0, 0, 0, 0, 0, 0, 0, 0, 0],
                            "syrup": [0, 0, 0, 0, 0, 0],
                            "soda": 0
                        };
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        save_cocktail_time() {
            var that = this;
            this.$axios
                .post(that.apiuri, { action: "savecocktailsettingtime", hid: that.selectedGid, setting: that.cocktailsetting_time })
                .then(function (response) {
                    if (response.data.status == 200) {
                        this.$confirm('保存成功')
                            .then(_ => {
                                that.getcocktailsettingv2(that.cocktailsetting_device);
                            })
                            .catch(_ => { });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        make_cocktail_moterstep() {
            var that = this;
            this.$axios
                .post(that.apiuri, { action: "make_cocktail_moterstep", id: that.cocktailsetting_device })
                .then(function (response) {
                    if (response.data.status == 200) {
                        this.$confirm('保存成功')
                            .then(_ => {
                                that.getcocktailsettingv2(that.cocktailsetting_device);
                            })
                            .catch(_ => { });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        make_cocktail_goods() {
            var that = this;
            this.$axios
                .post(that.apiuri, { action: "make_cocktail_goods", id: that.cocktailsetting_device })
                .then(function (response) {
                    if (response.data.status == 200) {
                        this.$confirm('保存成功')
                            .then(_ => {
                                that.getcocktailsettingv2(that.cocktailsetting_device);
                            })
                            .catch(_ => { });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        save (key, hid) {
            var that = this;
            this.$axios
                .post(that.apiuri, { action: "changehdinfo", hid: that.devicehdlist[hid].id, key: key, value: that.devicehdlist[hid][key] })
                .then(function (response) {
                    if (response.data.status == 200) {
                        this.$toast.success(this.language.actionsuccess, this.language.success);
                    } else {
                        this.$toast.error(this.language.actionfail, this.language.danger);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
    computed: {
        getTitleById() {
            return (gid) => {
                const matchedItem = this.goodslist.find(item => item.id === gid);
                return matchedItem ? matchedItem.title : '未配置';
            };
        },
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body" v-loading="loading">
                        <el-collapse v-model="cocktailsettingpage" accordion>
                            <el-collapse-item :title="$t('device.detail.cocktail.baseinfo')" name="base">
                                <div>
                                    <p>{{ $t("device.detail.cocktail.base") }}</p>
                                    <el-row>
                                        <el-col :span="6" v-for="(val2, index) in cocktailsetting.base" :key="index">
                                            <el-input :placeholder="'请输入基酒' + (index + 1) + '名称'"
                                                v-model="cocktailsetting.base[index]" clearable>
                                            </el-input>
                                        </el-col>
                                    </el-row>
                                </div>
                                <div>
                                    <p>糖浆</p>
                                    <el-row>
                                        <el-col :span="6" v-for="(val2, index) in cocktailsetting.syrup" :key="index">
                                            <el-input :placeholder="'请输入糖浆' + (index + 1) + '名称'"
                                                v-model="cocktailsetting.syrup[index]" clearable>
                                            </el-input>
                                        </el-col>
                                    </el-row>
                                </div>
                                <div>
                                    <p>苏打水</p>
                                    <el-input placeholder="请输入苏打水名称" v-model="cocktailsetting.soda" clearable>
                                    </el-input>
                                </div>
                                <div>
                                    <p>电机驱动步数</p>
                                    <el-row>
                                        <el-col :span="3" v-for="(val2, index) in cocktailsetting.moter" :key="index">
                                            <el-input :placeholder="'请输入电机' + (index + 1) + '步数'"
                                                v-model="cocktailsetting.moter[index]" clearable>
                                            </el-input>
                                        </el-col>
                                    </el-row>
                                </div>
                                <p style="text-align:center;margin-top:15px">
                                    <el-button type="primary" round @click="save_cocktail_base">保存</el-button>
                                    <el-button type="success" round @click="make_cocktail_goods">更新料表</el-button>
                                    <el-button type="success" round @click="make_cocktail_moterstep">更新电机</el-button>
                                </p>

                            </el-collapse-item>
                            <el-collapse-item title="货道设置" name="hdinfo">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>{{ $t("device.detail.cocktail.hdno") }}</th>
                                            <th>{{ $t("device.detail.cocktail.name") }}</th>
                                            <th>{{ $t("device.detail.cocktail.stock") }}</th>
                                            <th>{{ $t("device.detail.cocktail.price") }}</th>
                                            <th>{{ $t("device.detail.cocktail.sale_price") }}</th>
                                            <th>{{ $t("device.detail.cocktail.action") }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-if="devicehdlist">
                                            <tr v-for="(val, idx) in devicehdlist" :key="idx">
                                                <td>{{ val.no }}</td>
                                                <td>
                                                    <el-select v-model="val.gid" filterable placeholder="请选择商品"
                                                        size="small" @change="save('gid', idx)">
                                                        <el-option v-for="item in goodslist" :key="item.id"
                                                            :label="item.title" :value="item.id">
                                                        </el-option>
                                                    </el-select>
                                                </td>
                                                <td><el-input v-model="val.stock" placeholder="打酒量" size="small"
                                                        @change="save('stock', idx)"></el-input></td>
                                                <td><el-input v-model="val.price" placeholder="基础售价" size="small"
                                                        @change="save('price', idx)"></el-input></td>
                                                <td><el-input v-model="val.sale_price" placeholder="促销售价" size="small"
                                                        @change="save('sale_price', idx)"></el-input></td>
                                                <td>
                                                    <el-button type="primary" @click="makeorder(val.id)" round
                                                        size="mini">下发</el-button>
                                                    <el-button type="danger" @click="deletes('hd', val.id)" round
                                                        size="mini">删除</el-button>
                                                </td>
                                            </tr>
                                        </template>

                                        <tr v-if="devicehdlist.length < 45">
                                            <td colspan="6" style="text-align:center"
                                                @click="addhd(cocktailsetting_device)"><a href="javascript:;">追加货道</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </el-collapse-item>
                            <el-collapse-item title="时间设置" name="time">
                                <el-select v-model="selectedGid" filterable placeholder="请选择修改时间设置的货道"
                                    @change="getcocktailsettingtime">
                                    <el-option v-for="item in devicehdlist" :key="item.id"
                                        :label="getTitleById(item.gid)" :value="item.id">
                                    </el-option>
                                </el-select>
                                <div v-if="selectedGid">
                                    <div>
                                        <p>基酒</p>
                                        <el-row>
                                            <el-col :span="6" v-for="(val2, index) in cocktailsetting_time.base"
                                                :key="index">
                                                <el-input :placeholder="'请输入基酒' + (index + 1) + '时间'"
                                                    v-model="cocktailsetting_time.base[index]" clearable>
                                                    <template slot="append">秒</template>
                                                </el-input>
                                            </el-col>
                                        </el-row>
                                    </div>
                                    <div>
                                        <p>糖浆</p>
                                        <el-row>
                                            <el-col :span="6" v-for="(val2, index) in cocktailsetting_time.syrup"
                                                :key="index">
                                                <el-input :placeholder="'请输入糖浆' + (index + 1) + '时间'"
                                                    v-model="cocktailsetting_time.syrup[index]" clearable>
                                                    <template slot="append">秒</template>
                                                </el-input>
                                            </el-col>
                                        </el-row>
                                    </div>
                                    <div>
                                        <p>苏打水</p>
                                        <el-input placeholder="请输入苏打水时间" v-model="cocktailsetting_time.soda" clearable>
                                            <template slot="append">秒</template>
                                        </el-input>
                                    </div>
                                    <p style="text-align:center;margin-top:15px">
                                        <el-button type="primary" round @click="save_cocktail_time">保存</el-button>
                                    </p>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>